import { withRouter } from 'react-router-dom'
import React from 'react'
import { connect } from 'react-redux'
import { logUserOut } from '../actions/AuthActions'
import { ReactComponent as LogoutIcon } from '../assets/logout.svg'

const mapStateToProps = state => ({
  authenticated: state.auth.session.token !== false,
  name: state.auth.session.username,
  rma_zone_name: state.auth.session.rma_zone_name,
  lang: state.auth.lang
})

const mapDispatchToProps = dispatch => ({
    logUserOutAction: () => dispatch(logUserOut())
})

class HeaderComponent extends React.Component {

  render() {
    const {authenticated, name, rma_zone_name, logUserOutAction} = this.props

    return(
      <header>
        <div className="logo"/>
        { authenticated ? (
          <div className="nav">
            <div className="auth_info">
              <span className="auth_info_name">
                { name }
              </span>
              <span className="auth_info_rma_zone">
                { rma_zone_name }
              </span>
            </div>
            <button type="button" className="logout" onClick={logUserOutAction}>
              <LogoutIcon/>
              Wyloguj
            </button>
          </div>
        ):''}
      </header>
    )
  }
}
const Header = connect(mapStateToProps, mapDispatchToProps)(HeaderComponent)

export default withRouter(Header)
