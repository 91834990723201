import { GET_ORDER, RESET_ORDER, SET_DONE, LOGOUT_USER, SET_LANG, API_ERROR} from '../constants/ActionTypes'
import { push } from 'connected-react-router'
import http from 'axios'
import Popup from 'react-popup'
import intl from 'react-intl-universal'
import Odoo from '../utils/Odoo'
import { setQueries } from '../utils/Tools'

function api_error(dispatch, e){
  if (e && e.status === 403){
    Popup.alert(intl.get('Sesja wygasła'))
    dispatch({
      type: LOGOUT_USER
    })
  } else {
    dispatch({
      type: API_ERROR,
      message: e.message
    })
  }
}

export function loadTranslations(lang){
  return dispatch => {
    if(lang in intl.options.locales){
      selectLang(lang)(dispatch)
    } else {
      http.get(`translations/${lang}.json`).then(res => {
        intl.init({
          currentLocale: lang,
          locales: { [lang]: res.data }
        })
        selectLang(lang)(dispatch)
      })
    }
  }
}

export function selectLang(lang) {
  const query = setQueries({
    lang: lang
  })
  return dispatch => {
    dispatch({
      type: SET_LANG,
      payload: lang
    })
    document.querySelector('select.lang').value = lang
    dispatch(push({
      search: query
    }))
  }
}

export function getForm(kod, id) {
  let params = {
    code: kod
  }
  if(id){
    params.id = id
  }
  return dispatch => {
    Odoo.call('rma/get_rma_form', params).then(res => {
      dispatch({
        type: GET_ORDER,
        payload: {
          mode: 'form',
          data: res
        }
      })
    }).catch(error => {
      api_error(dispatch, error)
    })
  }
}

export function deselectOrder() {
  return dispatch => {
    dispatch({
      type: RESET_ORDER
    })
  }
}

export function sendData(data) {
  let params = {data}
  return dispatch => {
    Odoo.call('rma/finish', params).then(res => {
      if(res){
        dispatch({
          type: SET_DONE,
          payload: res
        })
      } else {
        dispatch({
          type: API_ERROR
        })
      }
    }).catch(error => {
      dispatch({
        type: API_ERROR,
        message: error.message
      })
    })
  }
}
