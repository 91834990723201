import React from 'react'
import intl from 'react-intl-universal'
import { connect } from 'react-redux'
import { getForm } from '../actions/RmaActions'

const mapStateToProps = state => ({
  authenticated: state.auth.session.id !== false,
  lang: state.auth.lang
})

const mapDispatchToProps = dispatch => ({
  getFormAction: (formCode) => dispatch(getForm(formCode))
})

class SearchComponent extends React.Component {
  constructor() {
    super()
    this.state = {
      formCode: ''
    }
  }

  componentDidMount() {
    document.getElementById('formCode').focus()
  }

  handleChange = (event) => {
    const {target} = event
    this.setState({
      [target.name]: target.value
    })
  }

  handleKey = (event) => {
    if (event.which === 13) {
      this.handleSubmit(event)
    }
  }

  handleSubmit = (event) => {
    event.preventDefault()
    const { getFormAction } = this.props
    const {target} = event
    const field = target.dataset.field
    const kod = this.state[field]
    if (kod){
      getFormAction(kod)
    }
  }

  render() {
    const { formCode } = this.state
    return (
      <div className="container">
        <form onSubmit={this.handleSubmit} data-field="formCode">
          <div className="ramka center searchForm">
            <h4>{intl.get('Obsługa RMA')}</h4>
            <div>
              <input
                type='text'
                name='formCode'
                id='formCode'
                placeholder={intl.get('Kod z formularza / nr zamówienia / Nazwisko')}
                autoComplete='off'
                value={formCode}
                onChange={this.handleChange}
                />
              <input type="submit" value="Dalej"/>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

const Search = connect(mapStateToProps, mapDispatchToProps)(SearchComponent)
export default Search
